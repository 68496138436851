import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";

import {CardWithBaseComponent} from "../../common/BaseCard";
import {getCompounds} from "../../../api/ChemSpider";
import {isNotUndefined} from "../../../utils/string";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn, ColumnFiltersState} from "@tanstack/react-table";
import { MaterialReactTable } from 'material-react-table';
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {FileDownload as FileDownloadIcon} from "@mui/icons-material";
import {exportToExcel} from "../../../utils/export_xlsx";
import {ChemSpiderChemicalData} from "./types";

const _ChemSpiderTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data ,
                                                                    setColumnFilters, columnFilters,
                                                                 // setTextSearch, textSearch,
                                                                    setTitle, isActive
                                                                   }) => {
    const UUID = '_ChemSpiderTableCard';
    const columnNames = Object.keys(ChemSpiderChemicalData);
    const columnsToView = ['name','molecularFormula','molecularWeight'];

    const columns = getColumns(ChemSpiderChemicalData);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    // SEARCH ------------

    const getListOfCompounds = async (term:string) => {

        setIsShow(false);
        try {
            setTitle(term);
            const data  = await getCompounds(term);
            if (isNotUndefined(data) && data.length > 0) {
                setData(data);
                setIsShow(true);
            }

        } catch (error: any) {
            console.log('captured error', error);
            setError(error);
            setIsShow(true);
        }
    }


    function _search(text: string) {
        getListOfCompounds(text);
    }

    const searchEvent = (event: { detail: string }) => {
        if(isActive){
            // setTextSearch(event.detail);
            _search(event.detail);
        }

    }

    // const reloadEvent = (event: { detail: any }) => {
    //     setTimeout(() => { _search(textSearch); }, 500);
    // }

    // EVENTS ------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
        // Events.on(EventsType.RELOAD, reloadEvent, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            // Events.off(EventsType.RELOAD, UUID);
        };
    }, [isActive]);

    // RENDER ------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
        <MaterialReactTable
            columns={columns}
            initialState={{
                density: 'compact',
                columnVisibility: _initial_columns_state,
                columnOrder: [
                    'mrt-row-actions',
                    'mrt-row-select',
                    'mrt-row-expand',
                    'name',
                    'molecularFormula',
                    'molecularWeight'
                ],
            }}
            data={data?? []}
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}


            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            /* ACTIONS */
            enableRowActions={true}
            renderRowActions={({ row }) => (
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Button variant="contained" size="small"
                            color="secondary"
                            onClick={() => {
                                Events.trigger(EventsType.SEARCH_BY_CSID, row.original.id);
                            }}>
                        CSID {row.original.id}
                    </Button>

                </Box>
            )}

            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "ChemSpider Data")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcel(table.getSelectedRowModel().rows, "ChemSpider Data")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}

        />
    );
};


const ChemSpiderTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_ChemSpiderTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(ChemSpiderTableCard);
