import React, { useState, useEffect, useMemo } from 'react';
import { ChartPie } from './ChartPie';
import { ChartPieComponentProps } from "./types";
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {isNotUndefined} from "../../utils/string";

const productBuildLabels = (data: any[]): string[] => {
    return data.map((row) => {
        let label = "";
        if (row.NAME) {
            label += `${row.NAME}\n`;
        }
        if (row.LOD) {
            label += `LOD: ${row.LOD}, `;
        }
        if (row.LOQ) {
            label += `LOQ: ${row.LOQ}\n`;
        }
        if (row.NUMBER_OF_ITEMS_PER_REPLICATE) {
            label += `Items per Replicate: ${row.NUMBER_OF_ITEMS_PER_REPLICATE}\n`;
        }
        if (row.NUMBER_OF_REPLICATES) {
            label += `Number of Replicates: ${row.NUMBER_OF_REPLICATES} \n`;
        }
        if (row.STD_DEV) {
            label += `STDEV: ${row.STD_DEV} \n`;
        }

        console.log(row.NUMBER_OF_REPLICATES);
        return label.slice(0, -2); // Remove trailing comma after LOQ
    });
};

const ChartPieProduct: React.FC<ChartPieComponentProps> = ({ data }) => {
    const filteredItems = data.items.filter(item => item.HEADER === "List of Products Analysis Values");

    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [showInCommon, setShowInCommon] = useState(true);
    const [selectedClass, setSelectedClass] = useState<string>('All');

    const handleProductChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        if (value.includes('all')) {
            setSelectedProducts(products.length === selectedProducts.length ? [] : products);
        } else {
            setSelectedProducts(value);
        }
    };

    const handleInCommonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInCommon(event.target.checked);
    };

    const handleClassChange = (event: SelectChangeEvent<string>) => {
        setSelectedClass(event.target.value);
    };

    // Extract unique products
    const products = Array.from(new Set(filteredItems.map(item =>
        `${item.DATA[0]?.PRODUCT || 'Unknown'} (${item.DATA[0]?.ANALYSIS_TYPE || 'Unknown'})`
    )));

    // Extract unique CLASS values
    const classOptions = useMemo(() => {
        const classes = new Set<string>();
        filteredItems.forEach(item => {
            item.DATA.forEach((row: any) => {
                if (row.CLASS) classes.add(row.CLASS);
            });
        });
        return ['All', ...Array.from(classes)];
    }, [filteredItems]);

    // Filter data by selected CLASS
    const classFilteredItems = useMemo(() => {
        if (selectedClass === 'All') return filteredItems;
        return filteredItems.map(item => ({
            ...item,
            DATA: item.DATA.filter((row: any) => row.CLASS === selectedClass),
        })).filter(item => item.DATA.length > 0);
    }, [filteredItems, selectedClass]);

    // Determine compounds to display
    const getCompounds = () => {
        const allCompounds = new Set<string>();
        const compoundOccurrences: { [key: string]: string[] } = {};

        classFilteredItems.forEach(item => {
            const productKey = `${item.DATA[0]?.PRODUCT || 'Unknown'} (${item.DATA[0]?.ANALYSIS_TYPE || 'Unknown'})`;
            if (selectedProducts.length === 0 || selectedProducts.includes(productKey)) {
                item.DATA.forEach((row: any) => {
                    const compound = row.COMPOUND_NAME || 'Unknown';
                    const value = Number(row.AVERAGE);
                    if (value > 0 && compound !== 'Unknown') { // Filter out 0, N/A, and undefined
                        allCompounds.add(compound);
                        if (!compoundOccurrences[compound]) {
                            compoundOccurrences[compound] = [];
                        }
                        compoundOccurrences[compound].push(productKey);
                    }
                });
            }
        });

        if (selectedProducts.length > 1) {
            if (showInCommon) {
                return Array.from(allCompounds).filter(compound =>
                    compoundOccurrences[compound].length === selectedProducts.length
                );
            } else {
                return Array.from(allCompounds).filter(compound =>
                    compoundOccurrences[compound].length < selectedProducts.length
                );
            }
        }
        return Array.from(allCompounds);
    };

    const compoundsToShow = getCompounds();

    return (
        <Box sx={{ p: 2 , textAlign: '-webkit-center' }}>
            <Box sx={{ display: 'flex', gap: 1, mb: 2, alignItems: 'center' }}>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Products</InputLabel>
                    <Select
                        multiple
                        value={selectedProducts}
                        onChange={handleProductChange}
                        renderValue={(selected) => selected.join(', ')}
                        label="Products"
                        size="small"
                    >
                        <MenuItem value="all">
                            <Checkbox checked={selectedProducts.length === products.length} />
                            <ListItemText primary="View All" />
                        </MenuItem>
                        {products.map((product) => (
                            <MenuItem key={product} value={product}>
                                <Checkbox checked={selectedProducts.includes(product)} />
                                <ListItemText primary={product} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selectedProducts.length > 1 && (
                    <FormControlLabel
                        control={<Checkbox checked={showInCommon} onChange={handleInCommonChange} />}
                        label="In Common"
                        sx={{ m: 0 }}
                    />
                )}
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Class Filter</InputLabel>
                    <Select
                        value={selectedClass}
                        onChange={handleClassChange}
                        label="Class Filter"
                        size="small"
                    >
                        {classOptions.map(classOption => (
                            <MenuItem key={classOption} value={classOption}>
                                {classOption}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {classFilteredItems
                    .filter(item => {
                        const productKey = `${item.DATA[0]?.PRODUCT || 'Unknown'} (${item.DATA[0]?.ANALYSIS_TYPE || 'Unknown'})`;
                        return selectedProducts.length === 0 || selectedProducts.includes(productKey);
                    })
                    .map((basketItem, index) => {
                        const pieData = basketItem.DATA
                            .filter((item: any) => {
                                const value = Number(item.AVERAGE);
                                return value > 0 && compoundsToShow.includes(item.COMPOUND_NAME);
                            })
                            .map((item: any) => ({
                                NAME: item.COMPOUND_NAME,
                                VALUE: Number(item.AVERAGE),
                                LOQ: item.LOQ,
                                LOD: item.LOD,
                                NUMBER_OF_ITEMS_PER_REPLICATE: item.NUMBER_OF_ITEMS_PER_REPLICATE,
                                NUMBER_OF_REPLICATES: item.NUMBER_OF_REPLICATES,
                                STD_DEV: item.STD_DEV,
                                UNIT: item.UNIT,
                            }));

                        if (pieData.length === 0) return null;

                        return (
                            <div key={index} style={{ marginBottom: '2rem', maxWidth: '250px', marginLeft: '50px' }}>
                                <h5>{`${basketItem.DATA[0]?.ANALYSIS_TYPE}`}</h5>
                                <h5>{`${basketItem.DATA[0]?.PRODUCT}`}</h5>
                                <h5>{`${basketItem.DATA[0]?.TEST_METHOD_CODE}`}</h5>
                                <h5>{`${basketItem.DATA[0]?.REPORT_PROJECT_ID}`}</h5>
                                <ChartPie data={pieData} buildLabels={productBuildLabels} />
                                <h5>{`[ ${basketItem.DATA[0]?.UNIT} ]`}</h5>
                            </div>
                        );
                    })}
            </div>
        </Box>
    );
};

export default ChartPieProduct;
