import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ChartBarProductsProps } from './types';
import ProductSelector from './ProductSelector';
import { transformData, getTooltipCallbacks } from './Chart.Common';
import { ChartOptions, Chart as ChartJS } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { LineAnnotationOptions } from 'chartjs-plugin-annotation';

// Register the annotation plugin
ChartJS.register(annotationPlugin);

const ChartBarProducts: React.FC<ChartBarProductsProps> = ({ data }) => {
    const chartRef = useRef<ChartJS<'bar'> | null>(null);
    const filteredItems = useMemo(() => data.filter(item => item.HEADER === "List of Products Analysis Values"), [data]);

    const [visualizationMode, setVisualizationMode] = useState<'direct' | 'percentage' | 'normalized' | 'logarithmic'>('logarithmic');
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [showInCommon, setShowInCommon] = useState(true);
    const [selectedClass, setSelectedClass] = useState<string>('All');

    const handleModeChange = (event: SelectChangeEvent<'direct' | 'percentage' | 'normalized' | 'logarithmic'>) => {
        setVisualizationMode(event.target.value as 'direct' | 'percentage' | 'normalized' | 'logarithmic');
    };

    const handleClassChange = (event: SelectChangeEvent<string>) => {
        setSelectedClass(event.target.value);
    };

    const classOptions = useMemo(() => {
        const classes = new Set<string>();
        filteredItems.forEach(item => {
            item.DATA.forEach((row: any) => {
                if (row.CLASS && row.AVERAGE != null && !isNaN(Number(row.AVERAGE))) {
                    classes.add(row.CLASS);
                }
            });
        });
        return ['All', ...Array.from(classes)];
    }, [filteredItems]);

    const classFilteredItems = useMemo(() => {
        if (selectedClass === 'All') return filteredItems;
        return filteredItems.map(item => ({
            ...item,
            DATA: item.DATA.filter((row: any) => row.CLASS === selectedClass),
        })).filter(item => item.DATA.length > 0);
    }, [filteredItems, selectedClass]);

    const chartData = transformData(
        classFilteredItems,
        selectedProducts,
        visualizationMode,
        (item) => `${item.DATA[0]?.PRODUCT || 'Unknown'} (${item.DATA[0]?.ANALYSIS_TYPE || 'Unknown'})`,
        (row) => Number(row.AVERAGE) || 0,
        (row) => row.COMPOUND_NAME,
        (row) => Number(row.STD_DEV) || null,
        (item) => item.DATA[0]?.UNIT || 'Unknown',
        showInCommon
    );

    const annotations = useMemo(() => {
        if (visualizationMode === 'normalized') return {};

        return chartData.datasets.reduce((acc, dataset, datasetIndex) => {
            chartData.labels.forEach((label, index) => {
                const stdev = dataset.errorBars?.[label]?.plus || 0;
                if (stdev) {
                    const value = dataset.data[index];
                    let yMin: number, yMax: number;

                    switch (visualizationMode) {
                        case 'direct':
                            yMin = value - stdev;
                            yMax = value + stdev;
                            break;
                        case 'percentage':
                            const totalForProduct = classFilteredItems
                                .find(item => `${item.DATA[0]?.PRODUCT || 'Unknown'} (${item.DATA[0]?.ANALYSIS_TYPE || 'Unknown'})` === dataset.label)
                                ?.DATA.reduce((sum: number, row: any) => sum + (Number(row.AVERAGE) || 0), 0) || 1;
                            const stdevPercentage = totalForProduct > 0 ? (stdev / totalForProduct) * 100 : 0;
                            yMin = value - stdevPercentage;
                            yMax = value + stdevPercentage;
                            break;
                        case 'logarithmic':
                            const logValue = Math.log10(value + 1);
                            const logStdev = Math.log10(value + stdev + 1) - logValue;
                            const logMaxInProduct = Math.max(...chartData.datasets[datasetIndex].data.map(v => Math.log10(v + 1)));
                            yMin = value - (logStdev / logMaxInProduct) * 100;
                            yMax = value + (logStdev / logMaxInProduct) * 100;
                            break;
                        default:
                            yMin = value - stdev;
                            yMax = value + stdev;
                    }

                    const annotationId = `errorBar-${datasetIndex}-${index}`;
                    acc[annotationId] = {
                        xMin: index - 0.2 + datasetIndex * 0.4,
                        xMax: index - 0.2 + datasetIndex * 0.4,
                        yMin,
                        yMax,
                        borderColor: 'rgba(0, 0, 0, 1)',
                        borderWidth: 1,
                    } as LineAnnotationOptions;
                }
            });
            return acc;
        }, {} as { [key: string]: LineAnnotationOptions });
    }, [chartData, visualizationMode, filteredItems]);

    const options: ChartOptions<'bar'> = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `Compound Values by Product (${visualizationMode.charAt(0).toUpperCase() + visualizationMode.slice(1)})`,
            },
            tooltip: {
                callbacks: getTooltipCallbacks(
                    classFilteredItems,
                    (item) => `${item.DATA[0]?.PRODUCT || 'Unknown'} (${item.DATA[0]?.ANALYSIS_TYPE || 'Unknown'})`,
                    (row) => row.COMPOUND_NAME,
                    (row) => Number(row.AVERAGE) || 0,
                    (row) => Number(row.STD_DEV) || null,
                    (item) => item.DATA[0]?.UNIT || 'Unknown',
                    visualizationMode
                ),
            },
            annotation: {
                annotations: annotations
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Compounds',
                },
            },
            y: {
                title: {
                    display: true,
                    text: visualizationMode === 'percentage' ? '% of Product Total' :
                        visualizationMode === 'normalized' ? 'Normalized Value (%)' :
                            visualizationMode === 'logarithmic' ? 'Log10 Normalized (%)' : 'Value',
                },
                beginAtZero: true,
            },
        },
    };

    useEffect(() => {
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, []);

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
                <ProductSelector
                    items={filteredItems}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    showInCommon={showInCommon}
                    setShowInCommon={setShowInCommon}
                />
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Visualization Mode</InputLabel>
                    <Select
                        value={visualizationMode}
                        onChange={handleModeChange}
                        label="Visualization Mode"
                        size="small"
                    >
                        <MenuItem value="direct">Direct Value</MenuItem>
                        <MenuItem value="percentage">% Range</MenuItem>
                        <MenuItem value="normalized">Unitary Normalization</MenuItem>
                        <MenuItem value="logarithmic">Logarithmic Scale</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Class Filter</InputLabel>
                    <Select
                        value={selectedClass}
                        onChange={handleClassChange}
                        label="Class Filter"
                        size="small"
                    >
                        {classOptions.map(classOption => (
                            <MenuItem key={classOption} value={classOption}>
                                {classOption}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                {classFilteredItems.length > 0 && chartData.labels.length > 0 ? (
                    <Box sx={{ width: '80%' }}>
                        <Bar
                            ref={chartRef}
                            data={chartData}
                            options={options}
                        />
                    </Box>
                ) : (
                    <Typography>No valid product analysis data available for selected class.</Typography>
                )}
            </Box>
        </Box>
    );
};

export default ChartBarProducts;
