import React, {useEffect} from 'react';

import {Box, IconButton} from "@mui/material";
import {Visibility as VisibilityIcon} from "@mui/icons-material";
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

import {MaterialReactTable} from 'material-react-table';

import Events from '../../../events';
import { EventsType } from '../../../events-types';

import { getStudiesRegEx, getByFilters, STUDIES_CBD, SC_THERAPEUTIC_INDICATIONS} from '../../../api/Studies';


import Button from "@mui/material/Button";
import {iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {isNotUndefined} from "../../../utils/string";
import {OnChangeFn, ColumnFiltersState} from "@tanstack/react-table";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";
import {TherapeuticIndicator} from "./types";

const _TherapeuticIndicatorsTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                               setData, data , setColumnFilters, columnFilters,
                                                               setTitle, setSubtitle,
                                                                            searchListByFilters,
                                                                            isActive
                                                               }) => {
    const UUID = '_TherapeuticIndicatorsTableCard';

    const columnNames = Object.keys(TherapeuticIndicator);
    const columnsToView = ['PRODUCT','STUDY_TYPE',
        'DOSE','TREATMENT_LENGTH','FORMULATION','DELIVERY','TRIAL_TYPE'];

    const columns = getColumns(TherapeuticIndicator);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    // SEARCH ---------------------

    function _search(text: string) {
        setIsShow(false);
        getStudiesRegEx(STUDIES_CBD, SC_THERAPEUTIC_INDICATIONS,'PRODUCT,TITLE', text)
            .then((response) => {

                if (isNotUndefined(response) && response.length > 1) {
                    setTitle(text);
                    setData(response);
                    setIsShow(true);
                }

            }).catch(error => {
            setError(error);
            setIsShow(true);
        });
    }

    const searchEvent = (event: { detail: string }) => {
        if(isActive){
            _search(event.detail);
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === SC_THERAPEUTIC_INDICATIONS){

            event.detail.filters.push({
                property: 'STUDY_CLASS',
                value: SC_THERAPEUTIC_INDICATIONS,
                operator: '=='
            });

            searchListByFilters?.(getByFilters, STUDIES_CBD, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ---------------------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);

    // RENDER ------------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (


                    <MaterialReactTable
                        columns={columns}
                        data={data?? []}
                        enableRowSelection //enable some features
                        enableColumnOrdering
                        enableGlobalFilter={false} //turn off a feature
                        enableDensityToggle={true}
                        initialState={{
                            columnVisibility: _initial_columns_state,
                            density: 'compact',
                        }}
                        /* SAVE FILTERS   */

                        onColumnFiltersChange={handleFilterFnChange}
                        state={{ columnFilters }}

                        /* ACTIONS */

                        enableRowActions={true}
                        renderRowActions={({ row }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        Events.trigger(EventsType.SEARCH_BY_STUDY_TI, row.original);
                                    }}
                                >
                                    <VisibilityIcon />
                                </IconButton>

                            </Box>
                        )}

                        positionToolbarAlertBanner="bottom"
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => exportToExcel(data, SC_THERAPEUTIC_INDICATIONS)}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Export All Data
                                </Button>
                                <Button
                                    disabled={
                                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                    }
                                    onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, SC_THERAPEUTIC_INDICATIONS)}

                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Export Selected Rows
                                </Button>
                            </Box>
                        )}
                        />
    );
};


const TherapeuticIndicatorsTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_TherapeuticIndicatorsTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(TherapeuticIndicatorsTableCard);
