import React, {JSX, useEffect} from "react";
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import Events from "../../../events";
import {EventsType} from "../../../events-types";
import {decodeAscii, isNotEmpty} from "../../../utils/string";
import {getCompoundByCSID} from "../../../api/ChemSpider";


const _ChemSpiderCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, setTitle, isActive }) => {

    const UUID = '_ChemSpiderCard';

    // SEARCH ---------------

    async function searchByCSID (csid: string) {

        if (csid === null) return;

        setIsShow(false);
        try {
            const response = await getCompoundByCSID(csid);

            if (isNotEmpty(response)) {
                console.log(' _ChemSpiderCard ===> ', response    );
                setTitle(csid);
                setData(response);
                setIsShow(true);
            }
        } catch (error:any) {
            setError(error);
            setIsShow(true);
        }
    }

    const searchByCSIDEvent = (event: { detail: any }) => {
        if (isActive){
            searchByCSID(event.detail);
        }
    }

    // EVENTS ------------

    useEffect(() => {

        Events.on(EventsType.SEARCH_BY_CSID, searchByCSIDEvent, UUID);

        return () => {
            Events.off(EventsType.SEARCH_BY_CSID, UUID);
        }
    }, [isActive]);

    // RENDER -------------------------

    interface ExpValue {
        value: string;
        source: string;
        link: string;
    }

    interface ExpItem {
        property: string;
        values: ExpValue[];
    }

    interface PredItem {
        property: string;
        value: string;
        error: string;
    }

    function createExperimentalHtmlTable(data: ExpItem[]): JSX.Element {
        return (
            <table style={{ width : '100%'}}>
                {(isNotEmpty(data)) && data.map(item => (
                    <React.Fragment key={item.property}>
                        <tr>
                            <td colSpan={4} style={{ fontWeight: 'bold', textAlign: 'center', background: 'antiquewhite' }}>{item.property}</td>
                        </tr>
                        {item.values.map((value, index) => (
                            <tr key={value.value} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' }}>
                                <td style={{ minWidth: '120px'}}>{value.value}</td>
                                <td><a href={value.link}>{value.source.trim()}</a></td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
            </table>
        );
    }

    function createPredictedHtmlTable(data: PredItem[]): JSX.Element {
        return (
            <table style={{ width : '100%'}}>
                <thead>
                <tr>
                    <td colSpan={4}
                        style={{ fontWeight: 'bold', textAlign: 'center', background: 'antiquewhite' }}
                    >ACD/Labs Percepta Platform - PhysChem Module</td>
                </tr>
                <tr>
                    <th style={{fontWeight: 'bold', textAlign: 'center'}}>Property</th>
                    <th style={{fontWeight: 'bold', textAlign: 'center'}}>Value</th>
                    <th style={{fontWeight: 'bold', textAlign: 'center'}}>Error</th>
                </tr>
                </thead>
                <tbody>
                {isNotEmpty(data) &&
                    data.map((item, index) => (
                        <tr key={item.property} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f5f5f5' }}>
                            <td>{item.property}</td>
                            <td>{item.value}</td>
                            <td>{item.error}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }


    return (
        <>
            {createExperimentalHtmlTable(data.experimental)}
            {createPredictedHtmlTable(data.predicted)}
        </>
    );

}

const ChemSpiderCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({
                                                                                          header,
                                                                                          width,
                                                                                          eventGroupToTrigger
                                                                                      }) => {
    return <CardWithBaseComponent cardComponent={_ChemSpiderCard} header={header} width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}/>;
};


export default React.memo(ChemSpiderCard);
