import React, {useEffect} from 'react';

import {Box, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import {FileDownload as FileDownloadIcon, Visibility as VisibilityIcon} from "@mui/icons-material";

import {MaterialReactTable} from 'material-react-table';

import {AROMA, getByFilters, getObjectsByRegEx} from '../../../api/Compounds';

import Events from '../../../events';
import { EventsType } from '../../../events-types';

import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";


import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn, ColumnFiltersState} from "@tanstack/react-table";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";
import {isValidIchiKey} from "../../../utils/string";
import {Aroma} from "./types";


const _AromaTable: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                          setData, data, isActive,
                                                          setColumnFilters, columnFilters,
                                                          searchListByFilters,
                                                          setTitle, setSubtitle,searchListBy
                                                      }) => {
    const UUID = '_AromaTable';

    const columnNames = Object.keys(Aroma);
    const columnsToView = ['CHEMICAL_TYPE','COMPOUND_NAME','ODOR', 'BP', 'VAPOR_PRESSURE', 'ODOR_THRESHOLD'];

    const columns = getColumns(Aroma);

    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);


    // SEARCH -----------------

    function _search(text: string) {
        if (text.length > 0) {
            if (isValidIchiKey(text)) {
                searchListBy?.(getObjectsByRegEx, AROMA, 'INCHIKEY',text);
            } else {
                searchListBy?.(getObjectsByRegEx, AROMA, 'ODOR,CHEMICAL_TYPE,COMPOUND_NAME', text);
            }
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            _search(event.detail);
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === AROMA){
            searchListByFilters?.(getByFilters, AROMA, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ------------------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);

    // RENDER -----------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };


    return (
        <MaterialReactTable
            columns={columns}
            data={data?? []}
            enableColumnFilterModes
            enableRowSelection //enable some features
            enableColumnOrdering
            enableGlobalFilter={false} //turn off a feature
            enableDensityToggle={true}
            initialState={{
                columnVisibility: _initial_columns_state,
                density: 'compact',
            }}
            /* SAVE FILTERS   */

            onColumnFiltersChange={handleFilterFnChange}
            state={{ columnFilters }}

            /* ACTIONS */
            enableRowActions={true}
            renderRowActions={({ row }) => (

                <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>

                    <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                            Events.trigger(EventsType.SEARCH_BY_AROMA, row.original);
                        }}>
                        <VisibilityIcon />
                    </IconButton>
                </Box>

            )}
            /*  DOWNLOAD */
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        onClick={() => exportToExcel(data, "Aroma")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "Aroma")}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />

    );
};

const AromaTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width , eventGroupToTrigger}) => {
    return <CardWithBaseComponent cardComponent={_AromaTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(AromaTable);
