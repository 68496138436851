import React, { useEffect } from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import { PRODUCTS_ANALYSIS_METHODS, deleteDoc, getObjectByDoc, getObjectsByRegEx, save } from '../../../api/Products';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";
import { CardWithBaseComponent } from "../../common/BaseCard";
import { isNotEmpty } from "../../../utils/string";
import { renderFields } from "../../../utils/pair_entry";
import FormEditModal from '../../common/FormEditModal';
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import { useRoles } from "../../../App.Context";
import { ProductAnalysisMethod } from "./types";

const _PAMethodCard: React.FC<iBaseInnerCardProps> = ({
                                                          setIsShow,
                                                          setError,
                                                          setData,
                                                          data,
                                                          isActive,
                                                          setTitle,
                                                          searchBy,
                                                          searchByDoc
                                                      }) => {
    const UUID = '_PAMethodCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK
    const saveData = (data: any) => {
        save(PRODUCTS_ANALYSIS_METHODS, data).then((res) => {
            setData(res);
            setIsShow(true);
            setTitle(res.TEST_METHOD_CODE || ''); // Set TITLE to TEST_METHOD_CODE after save
        }).catch(setError);
    };

    const deleteData = (data: any) => {
        deleteDoc(PRODUCTS_ANALYSIS_METHODS, data).then((res) => {
            setData({});
            setIsShow(false);
            setTitle(''); // Clear TITLE on delete
        }).catch(setError);
    };

    const undo = (undoData: any) => {
        Object.assign(data, undoData);
        save(PRODUCTS_ANALYSIS_METHODS, data).then((res) => {
            setData(res);
            setIsShow(true);
            setTitle(res.TEST_METHOD_CODE || ''); // Set TITLE to TEST_METHOD_CODE after undo
        }).catch(setError);
    };

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // SEARCH FUNCTIONS
    const searchEvent = (event: { detail: string }) => {
        if (!isActive) return;
        searchByTextEvent(event);
    };

    const searchByTextEvent = async (event: { detail: string }) => {
        if (!isActive) return;
        searchBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS,
            'CLASS,TEST_METHOD_CODE,MATRIX_CODE,METHOD_DESCRIPTION', event.detail);
    };

    const searchByObjectEvent = (event: { detail: any }) => {
        if (!isActive) return;
        searchByDoc?.(getObjectByDoc, PRODUCTS_ANALYSIS_METHODS, event.detail, true, 'PRODUCTS ANALYSIS METHOD');
    };

    const searchMethodCode = async (event: { detail: any }) => {
        if (!isActive) return;
        searchBy?.(getObjectsByRegEx, PRODUCTS_ANALYSIS_METHODS, 'TEST_METHOD_CODE', event.detail);
    };

    // EVENTS
    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD, searchByObjectEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE, searchMethodCode, UUID);

        // Set initial TITLE when data loads
        if (data && data.TEST_METHOD_CODE) {
            setTitle(data.TEST_METHOD_CODE);
        }

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD, UUID);
            Events.off(EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE, UUID);
        };
    }, [isActive, data]);

    // RENDER
    return (
        <>
            {renderFields(ProductAnalysisMethod, data)}

            {!hasReadOnlyRole && (
                <>
                    <FormEditModal save={saveData} data={data} schema={ProductAnalysisMethod} />
                    <DisplayDeleteModal deleteDoc={deleteData} data={data} schema={ProductAnalysisMethod} />
                </>
            )}

            <AuditModal id={data._id} undo={undo} />
        </>
    );
};

const ProductAnalisysMethodCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({
                                                                                                     header,
                                                                                                     width,
                                                                                                     eventGroupToTrigger
                                                                                                 }) => {
    return (
        <CardWithBaseComponent
            cardComponent={_PAMethodCard}
            header={header}
            width={width}
            eventGroupToTrigger={eventGroupToTrigger}
        />
    );
};

export default React.memo(ProductAnalisysMethodCard);
