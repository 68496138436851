import React, { useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface ProductSelectorProps {
    items: any[]; // Array of basket items
    selectedProducts: string[];
    setSelectedProducts: (products: string[]) => void;
    showInCommon: boolean;
    setShowInCommon: (value: boolean) => void;
}

const ProductSelector: React.FC<ProductSelectorProps> = ({ items, selectedProducts, setSelectedProducts, showInCommon, setShowInCommon }) => {
    const products = Array.from(new Set(items.map(item => {
        if (item.DATA && item.DATA[0] && item.DATA[0].PRODUCT) {
            // Use PRODUCT and ANALYSIS_TYPE if PRODUCT exists
            return `${item.DATA[0].PRODUCT || 'Unknown'} (${item.DATA[0].ANALYSIS_TYPE || 'Unknown'})`;
        } else {
            // Fallback to TITLE if PRODUCT is missing
            return `${item.TITLE || 'Unknown'}`;
        }
    })));

    const labelText = items.length > 0 && items[0].DATA && items[0].DATA[0] && items[0].DATA[0].PRODUCT ? "Products" : "Batches";

    const handleProductChange = (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value as string[];
        if (value.includes('all')) {
            setSelectedProducts(products.length === selectedProducts.length ? [] : products);
        } else {
            setSelectedProducts(value);
        }
    };

    const handleInCommonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowInCommon(event.target.checked);
    };

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>{labelText}</InputLabel>
                <Select
                    multiple
                    value={selectedProducts}
                    onChange={handleProductChange}
                    renderValue={(selected) => selected.join(', ')}
                    label={labelText}
                    size="small"
                >
                    <MenuItem value="all">
                        <Checkbox checked={selectedProducts.length === products.length} />
                        <ListItemText primary="View All" />
                    </MenuItem>
                    {products.map((product) => (
                        <MenuItem key={product} value={product}>
                            <Checkbox checked={selectedProducts.includes(product)} />
                            <ListItemText primary={product} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {selectedProducts.length > 1 && (
                <FormControlLabel
                    control={<Checkbox checked={showInCommon} onChange={handleInCommonChange} />}
                    label="In Common"
                    sx={{ m: 0 }}
                />
            )}
        </Box>
    );
};

export default ProductSelector;
